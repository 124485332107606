const TipAwsDotnet = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>AWS &amp; .NET</div>
      </h1>
    </div>
  </div>
);

export default TipAwsDotnet;