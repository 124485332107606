import { useLocation } from 'react-router-dom';

const SidebarService = {
    IsVisible: function() {
        if(useLocation().pathname.startsWith('/dev-tips'))
        {
            return true;
        }

        return false
    }
};

export default SidebarService;