import * as React from 'react';
import { Container } from 'reactstrap';
import SidebarService from '../services/SidebarService';
import NavMenu from './NavMenu';
import Sidebar from './Sidebar';

const Layout = (props: { children?: React.ReactNode }) => {
    return (
        <React.Fragment>
            <NavMenu/>
            <div className='page'>
                <Sidebar />
                <Container className={SidebarService.IsVisible() ? 'sidebar-visible' : ''}>
                    {props.children}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Layout;