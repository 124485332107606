const TipPipelines = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>Pipelines</div>
      </h1>
    </div>
  </div>
);

export default TipPipelines;