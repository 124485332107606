const TipTesting = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>Testing</div>
      </h1>
    </div>
  </div>
);

export default TipTesting;