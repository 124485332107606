const DevTips = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>Tips for Developers</div>
      </h1>
    </div>
    <p>
      After working with a large number of new developers and having to learn a lot of these tips myself, 
      some times far later than I should have, here are my tips for things to know as a dotnet developer.
    </p>
    <p>
      These tips are my own personal opinions/tips, and to not reflect the options or standards of my current
      or previous employers.
    </p>
    <div className="tip">
      <h2>Dependency Injection</h2>
      <p>
        Understanding how to set up dependency injection works, how to set up your services and allow 
        for mocking your services when unit testing is a critical part of dotnet software development.<br />
        It is important to understand how to set up the service providers, different scope types
        and when a new scope is created.
      </p>
      <p>
        You can learn more around dependency injection from 
        the <a href="https://docs.microsoft.com/en-us/aspnet/core/fundamentals/dependency-injection" target="_blank" rel="noopener noreferrer">Microsoft developer site</a>.
      </p>
    </div>
    <div className="tip">
      <h2>Structured Logging</h2>
      <p>
        Logging out debug messages, information and errors is really important in understanding what your 
        code is doing once it is deployed.
      </p>
      <p>
        In order to be able make sense and find log messages when logging vast quantities of data to your logging system
        you really should be using a structured logging library like Serilog.<br />
        Structured logging allow for consistent logging messages and being able to query logs quicker by being
        able to look for key attributes in your logs.
      </p>
      <p>
      <a href="https://serilog.net/" target="_blank" rel="noopener noreferrer">Serilog</a> is a great library for dotnet logging and has information around how
      best to write and enrich your log messages.
      </p>
    </div>
    <div className="tip">
      <h2>Unit Testing</h2>
      <p>
        There are a lot of differing opinions about how best to write unit tests, but you should write tests for all the services that 
        create. This will allow you to check that your code works as expected in isolation and to test a lot of the edge cases that 
        manual testing may not cover. 
      </p>
      <p>
        Unit test should be the following:
        <ul>
          <li>Fast - they are going to be run often, and should be run on every build of your code, so they need to run fast.</li>
          <li>A unit test - they should only test 1 single service. They should not be ab end-to-end test.</li>
          <li>Consistent - No matter when how many times or the order you run unit tests, they should produce the same results.</li>
        </ul>
      </p>
    </div>
    <div className="tip">
      <h2>AWS Serverless Tools</h2>
      <p>
        If using AWS services to run your code, there are a number of key services to understand.<br />
        Understanding all of these services is going to require a lot of learning and hands on experience, which you can do a lot
        yourself with online learning and signing up for a free AWS account to play with.
      </p>
      <p>
        The key parts of AWS that I have found are essential in knowing are:
        <ul>
          <li>IAM</li>
          <li>Lambdas &amp; Containers</li>
          <li>CloudWatch</li>
          <li>CloudFormation or AWS CDK</li>
        </ul>
      </p>
    </div>
  </div>
);

export default DevTips;