import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header className='border-bottom box-shadow'>
                <Container>
                    <Navbar expand="sm" variant="light" bg="light">
                            <NavbarBrand tag={Link} to="/"><span>Grant</span><span>is</span><span>a</span><span>coder</span></NavbarBrand>
                            <NavbarToggler onClick={this.toggle} className="mr-2 navbar-light"/>
                            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                                <ul className="navbar-nav flex-grow">
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/">Career</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/contact">Contact</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/dev-tips">Tips for devs</NavLink>
                                    </NavItem>
                                </ul>
                            </Collapse>
                    </Navbar>
                </Container>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
