import './Home.css';

const Home = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>Career</div>
      </h1>
    </div>
    <div className="job">
      <div className="logo openpay"></div>
      <div className="description">
        <h2>Openpay</h2>
        <div className="title">Engineering Team Lead, Since February 2020</div>
        <p>
          Combining all the skills that I learned at LiveHire and all my experience with large finance applications
          joining Openpay was a easy decision. I knew that I could hit the ground running and really make an impact
          on the business.
        </p>
        <p>
          After joining Openpay as a senior .NET developer, we went in to working remote due to Covid and the future
          of the business became uncertain. In order to have a clearer focus on key parts of the business, I became 
          the development lead on our business to business solutions.
        </p>
        <p>
          It is a fantastic place to work, with a whole bunch of teams working really well together to deliver 
          great systems for our clients and end customers.
        </p>
        <p>
          Continuing to build on my skills, a lot of focus has been on:
        </p>
        <ul>
          <li>Development - dotnet core, NodeJs, Typescript and different databases</li>
          <li>CI/CD tooling - managing Azure DevOp pipelines, testing and deployment</li>
          <li>AWS - As we build our platform for scale and localisation, I continue to explore different aspects of AWS</li>
        </ul>
      </div>
    </div>

    <div className="job">
      <div className="logo livehire"></div>
      <div className="description">
        <h2>LiveHire</h2>
        <div className="title">Senior Software Developer, August 2019 - February 2020</div>
        <p>
          After spending over a decade working with .NET Framework and a monolith application, it was a joy to join a modern company with
          a great vision for their products and acknowledgment that solutions need to be build right, not just following what has always been done.
        </p>
        <p>
          This was a great environment to learn new ways of working and technology from the people around me. This is where I got my first 
          real world experience with cloud services and started to build AWS lambdas and dynamodb.<br />
          The systems we made were built to scale and were fully automated with their build, test and deployment.
        </p>
        <p>
          While working at LiveHire I was able to really get an understanding of:
        </p>
        <ul>
          <li>AWS - including Lambdas, CloudWatch, DynamoDB and SSM</li>
          <li>CI/CD tooling - including Azure DevOps, Octopus, TeamCity and Terraform</li>
          <li>Development options - Docker, containers and structured logging</li>
        </ul>
      </div>
    </div>

    <div className="job">
      <div className="logo illion"></div>
      <div className="description">
        <h2>illion</h2>
        <div className="title">Solution Architect, 2017 - August 2019</div>
        <p>
          As the platform and demands grew working on the decisioning systems at Dun and Bradstreet, combined with being purchased and 
          rebranded to illion, I made the transition to being a full time Solution Architect. Managing people and helping them
          grow, combined with all the technical requirements of my role was just too much, so I chose to focus on just the 
          architecture role and help the business/developers make the best decisions.
        </p>
        <p>
          During this time I performed a number of data center migrations and worked through complex issues with the systems 
          around performance, stability and security.<br />
          The new technology that I was really able to dig deep on during this period included:
        </p>
        <ul>
          <li>System monitoring including Dynatrace and database performance</li>
          <li>Deployment tool Octopus and load balancing &amp; failover</li>
          <li>Security systems including Web Application Firewalls and deeper understanding of SSL/encryption</li>
        </ul>
      </div>
    </div>

    <div className="job">
      <div className="logo dnb"></div>
      <div className="description">
        <h2>Dun &amp; Bradstreet</h2>
        <div className="title">Development Manager, 2009 - 2017</div>
        <p>
          As the business grew, we took on more clients and had to be quicker at delivering solutions, so I became the core 
          development leader and manager.
        </p>
        <p>
          The challenges with this role meant that I wore many different hats: Developer, mentor, manager and solution designer.
          With so much going on the days and years just flew by. The business continued to grow and so did the platform that
          we were looking after.
        </p>

        <div className="title">Developer, Late 2006 - 2009</div>
        <p>
          After graduating with a Bachelor degree in Software Development and Internet Technology I joined a small company called
          Decision Intellect (which would later become part of Dun and Bradstreet), working on their credit decisioning application
          which would end up being my focus for the next 12 years.
        </p>
      </div>
    </div>
  </div>
);

export default Home;
