const TipSoftware = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>Software Tools</div>
      </h1>
    </div>
    <p>
      After experiencing issues with getting software licenses provided by employers, my general 
      approach to software licenses is if your employer wont pay for it but it is going to make
      your day-to-day work efficient, look to purchase your own personal licenses.
    </p>
    <p>
      Having the right tools to make you an efficient developer can help your personal career growth,
      by allowing you to develop faster, with less bugs, following standards and without spelling mistakes.
    </p>
    <p>Here are some recommendations of tools and systems to use when creating applications.</p>
    <div className="tip">
      <h2>Rider</h2>
      <p>
        <a href="https://www.jetbrains.com/rider/" target="_blank" rel="noopener noreferrer">Rider by JetBrains</a> is a good alternative to Visual Studio.<br />
        I use it as my default editor for C# development as it performs better than Visual Studio with Resharper tools installed, and helps me write
        better clean code than just the default Visual Studio installation.
      </p>
    </div>
    <div className="tip">
      <h2>Visual Studio Code</h2>
      <p>
        <a href="https://code.visualstudio.com/" target="_blank" rel="noopener noreferrer">Visual Studio Code</a> works really well when working with Typescript 
        and other front end languages. It is a lightweight editor that is good for editing a lot of development related files, and when combined with
        many of the additional plugins, you will struggle to live without it when working on `infrastructure as code` development
      </p>
    </div>
    <div className="tip">
      <h2>API Testing</h2>
      <p>
        <a href="https://www.postman.com/" target="_blank" rel="noopener noreferrer">Postman</a> is my application of choice when it comes to testing API
        connectivity and providing examples for customers to be able to view and play with.
      </p>
    </div>
    <div className="tip">
      <h2>Password Manager</h2>
      <p>
        Having a password manager is a really good idea for anyone. As a developer you will end up having access to many different systems and websites,
        and you should not use the same password across different sites.<br />
        <a href="https://www.lastpass.com/" target="_blank" rel="noopener noreferrer">LastPass</a> is the password manager I am most familiar with
        as it has good enterprise support so you can share credentials securely between all team members (such as API keys).
      </p>
    </div>
  </div>
);

export default TipSoftware;