const Contact = () => (
  <div>
    <div className="page-heading">
      <h1>
        <div>Contact</div>
      </h1>
    </div>
    <p>
        I am still setting up contact methods. Check back soon.
    </p>
  </div>
);

export default Contact;