import * as React from 'react';
import {  Nav, Collapse, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as IconsMd from 'react-icons/md'
import SidebarService from '../services/SidebarService';

const Sidebar = (props: { children?: React.ReactNode }) => {
    const [isOpen, setIsOpen] = React.useState(window.innerWidth >= 768);
    const toggle = () => setIsOpen(!isOpen);

    if(!SidebarService.IsVisible())
    {
        return null;
    }

    return (
        <Nav vertical className="sidebar mr-2 navbar-dark"> 
            <div onClick={toggle} className="sidebar-toggle">{isOpen ? <IconsMd.MdMenuOpen color='#fff' size={30} /> :  <IconsMd.MdMenu color='#fff' size={30} />}</div>
            <Collapse isOpen={isOpen} timeout={0}>
                <ul className="navbar-nav">
                    <NavItem>
                        <NavLink tag={Link} className="text-light" to="/dev-tips">General Tips</NavLink>
                        <ul>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/dev-tips/software">Software Tools</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/dev-tips/pipelines">Pipelines</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/dev-tips/testing">Testing</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-light" to="/dev-tips/aws-dotnet">AWS &amp; .NET</NavLink>
                            </NavItem>
                        </ul>
                    </NavItem>
                </ul>
            </Collapse>
        </Nav>
    );
}


export default Sidebar;