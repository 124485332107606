import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import Home from './components/Home';
import Contact from './components/Contact';
import DevTips from './components/DevTips';
import DevTipsSoftware from './components/DevTips/Software';
import DevTipsPipelines from './components/DevTips/Pipelines';
import DevTipsTesting from './components/DevTips/Testing';
import DevTipsAwsDotnet from './components/DevTips/AwsDotnet';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/dev-tips' element={<DevTips />} />
          <Route path='/dev-tips/software' element={<DevTipsSoftware />} />
          <Route path='/dev-tips/pipelines' element={<DevTipsPipelines />} />
          <Route path='/dev-tips/testing' element={<DevTipsTesting />} />
          <Route path='/dev-tips/aws-dotnet' element={<DevTipsAwsDotnet />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
